<template>
  <div>
    <!-- Header: Personal Info -->
    <div class="d-flex">
      <feather-icon icon="FileIcon" size="19" />
      <label class="mb-0 ml-50"> الوثائق الخاصة بالموظف </label>
    </div>

    <!-- Form: Personal Info Form -->
    <b-form class="mt-1">
      <b-row>
        <!-- Field: Birth Date -->
        <b-col cols="12" md="4">
          <b-form-group label="Resume" label-for="username">
            <b-form-file
              v-model="file"
              placeholder=""
              drop-placeholder="Drop file here..."
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <!-- Field: Birth Date -->
        <b-col cols="12" md="4">
          <b-form-group label="offer letter" label-for="username">
            <b-form-file
              v-model="file"
              placeholder=""
              drop-placeholder="Drop file here..."
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <!-- Field: Birth Date -->
        <b-col cols="12" md="4">
          <b-form-group label="joining letter" label-for="username">
            <b-form-file
              v-model="file"
              placeholder=""
              drop-placeholder="Drop file here..."
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <!-- Field: Birth Date -->
        <b-col cols="12" md="4">
          <b-form-group label="contract and argeement" label-for="username">
            <b-form-file
              v-model="file"
              placeholder=""
              drop-placeholder="Drop file here..."
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <!-- Field: Birth Date -->
        <b-col cols="12" md="4">
          <b-form-group label="Id Proof" label-for="username">
            <b-form-file
              v-model="file"
              placeholder=""
              drop-placeholder="Drop file here..."
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row class="mt-2">
        <b-col>
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          >
            <span> حفظ </span>
          </b-button>
          <b-button
            variant="outline-secondary"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          >
            <span> إعادة تعيين </span>
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormFile,
  BFormInput,
  BFormRadioGroup,
  BFormCheckboxGroup,
  BButton,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import { ref } from "@vue/composition-api";
import vSelect from "vue-select";
export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormFile,
    flatPickr,
    BFormInput,
    vSelect,
    BFormRadioGroup,
    BFormCheckboxGroup,
    BButton,
  },
  setup() {
    return {
      file: [],
    };
  },
};
</script> 

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>