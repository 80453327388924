<template>
  <div>
    <!-- Media -->
    <!-- <validation-observer ref="simpleRules"> -->
    <b-media class="mb-2">
      <template #aside>
        <b-avatar ref="previewEl" size="90px" :src="photo" rounded />
      </template>
      <h4 class="mb-1">
        {{ userData.first_name }}
      </h4>
      <div class="d-flex flex-wrap">
        <b-button variant="primary" @click="$refs.refInputEl.click()">
          <input
            ref="refInputEl"
            type="file"
            class="d-none"
            @input="inputImageRenderer"
          />
          <span class="d-none d-sm-inline">تحديث صورة</span>
          <feather-icon icon="EditIcon" class="d-inline d-sm-none" />
        </b-button>
        <b-button variant="outline-secondary" class="ml-1">
          <span class="d-none d-sm-inline">إلغاء</span>
          <feather-icon icon="TrashIcon" class="d-inline d-sm-none" />
        </b-button>
      </div>
    </b-media>

    <!-- User Info: Input Fields -->
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>
          <!-- Field: Username -->
          <b-col cols="12" md="4">
            <b-form-group label="الاسم" label-for="username">
              <validation-provider #default="{ errors }" name="name" rules="minname:2|required">
                <b-form-input id="username" v-model="userData.first_name" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Field: Full Name -->
          <b-col cols="12" md="4">
            <b-form-group label="النسبة" label-for="full-name">
              <validation-provider
                #default="{ errors }"
                name="full-name"
                rules="required"
              >
                <b-form-input id="full-name" v-model="userData.last_name" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Field: Email -->

          <!-- Field: Status -->
          <b-col cols="12" md="4">
            <b-form-group label="الجنس" label-for="gender">
              <validation-provider #default="{ errors }" name="gender" rules="required">
                <v-select
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  v-model="userData.gender"
                  :reduce="(val) => val.value"
                  :options="genderOptions"
                  :clearable="false"
                  input-id="user-status"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Field: Role -->
          <b-col cols="12" md="4">
            <b-form-group label="الدور" label-for="user-role">
              <validation-provider #default="{ errors }" name="role" rules="required">
                <v-select
                  :options="roleOptions"
                  v-model="userData.role_id"
                  :reduce="(val) => val.value"
                  input-id="user-role"
                  :multiple="true"

                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="4">
            <b-form-group label="القسم" label-for="user-department">
              <validation-provider #default="{ errors }" name="department" rules="required">
                <v-select
                  :options="departmentOptions"
                  v-model="userData.department_id"
                  :reduce="(val) => val.value"
                  input-id="user-role"
               
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="4">
            <b-form-group label="المنصب" label-for="user-department">
              <validation-provider #default="{ errors }" name="position" rules="">
                <v-select
                  :options="positionOptions"
                  v-model="userData.position_id"
                  :reduce="(val) => val.value"
                  input-id="user-role"
               
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
         
          <b-col cols="12" md="4">
            <b-form-group label="الكود" label-for="user-department">
              <validation-provider #default="{ errors }" name="|ؤخيث" rules="">
                <b-form-input     v-model="userData.employee_code" type="text" id="input-title" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        
          <b-col md="6" xl="3" v-if="userData.role_id.includes(3) || userData.role_id.includes(6)">
              <validation-provider
                #default="{ errors }"
                name="service"
                rules="required"
              >
                <b-form-group
                  label-for="service"
                  :state="errors.length > 0 ? false : null"
                >
                  <label> الخدمة</label>
                  <v-select
                    v-model="userData.main_service_id"
                    :reduce="(val) => val.value"
                    :options="optionService"

                  />
                </b-form-group>
                  <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
          <!-- Field: Birth -->
          
          <b-col cols="12" md="6" lg="4">
            <b-form-group label="تاريخ الميلاد" label-for="birth-date">
              <validation-provider #default="{ errors }" name="date" rules="required">
                <flat-pickr
                  class="form-control"
                  v-model="userData.birth_date"
                  placeholder="YYYY-MM-DD"
                  :config="{ maxDate: max }"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- Field: Mobile -->
          <b-col cols="12" md="6" lg="4">
            <b-form-group label="الرقم" label-for="mobile">
              <validation-provider #default="{ errors }" name="mobile" rules="max:14|regex:^([0-9]+)$|required">
                <b-form-input id="mobile" v-model="userData.phone" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6" lg="4">
            <b-form-group label="تاريخ التوظيف" label-for="birth-date">
              <validation-provider #default="{ errors }" name="date" rules="required">
                <flat-pickr
                  class="form-control"
                  v-model="userData.hire_date"
                  placeholder="YYYY-MM-DD"
                  :config="{ maxDate: max }"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
           <b-col cols="12" md="6" lg="4">
            <b-form-group label="الايميل" label-for="email">
              <validation-provider #default="{ errors }" name="email" rules="email|required">
                <b-form-input id="email"  type="email" v-model="userData.email" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
          <b-form-group>
                <b-form-checkbox
                v-model="isBenificary"
@change="updtaePer()"
                >
                قسم التسجيل
                </b-form-checkbox>
              </b-form-group>
            </b-col>
        </b-row>
        <div class="d-flex mt-2">
          <feather-icon icon="MapPinIcon" size="19" />
          <label class="mb-0 ml-50" style="font-size: 15px"> العنوان </label>
        </div>

        <b-row class="mt-1">
          <b-col cols="12" md="6" lg="4">
            <b-form-group label="العنوان الحالي" label-for="country">
              <validation-provider #default="{ errors }" name="country" rules="required">
                <b-form-input id="country" placeholder=" " v-model="userData.address" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <div class="d-flex mt-2">
          <feather-icon icon="LogInIcon" size="19" />
          <label class="mb-0 ml-50" style="font-size: 15px"> معلومات الدخول </label>
        </div>

        <br /><br />
        <b-row>
          <b-col cols="12" md="4">
            <b-form-group label="اسم المستخدم" label-for="username">
              <validation-provider #default="{ errors }" name="username" rules="required">
                <b-form-input id="username"  v-model="userData.username" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="4">
            <b-form-group label-for="كلمة المرور" label="كلمة المرور">
              <validation-provider
                #default="{ errors }"
                name="Password"
                vid="password"
                rules="password"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="register-password2"
                    class="form-control-merge"
                    :type="passwordFieldType"
                    :state="errors.length > 0 ? false : null"
                    name="register-password"
                    v-model="userData.password"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="4">
            <b-form-group label-for="تأكيد كلمة المرور" label="تأكيد كلمة المرور">
              <validation-provider
                #default="{ errors }"
                name="confirmPassword"
                vid="confirmPassword"
                rules="confirmed:password"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="register-password"
                    class="form-control-merge"
                    type="password"
                    :state="errors.length > 0 ? false : null"
                    name="register-password"
                    v-model="userData.confirm_password"
                    placeholder="············"
                  />
                </b-input-group>

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
      <!-- Form: Personal Info Form -->

      <!-- Field: Country -->

      <!-- PERMISSION TABLE -->
      <!-- <b-card no-body class="border mt-1">
        <b-card-header class="p-1">
          <b-card-title class="font-medium-2">
            <feather-icon icon="FileIcon" size="18" />
            <span class="align-middle ml-50">القسم</span>
          </b-card-title>
        </b-card-header>
        <b-col cols="12" md="4">
          <b-form-group label="رقم الموظف" label-for="username">
            <b-form-input id="username" />
          </b-form-group>
        </b-col>


        <b-col cols="12" md="4">
          <b-form-group label="القسم" label-for="user-status">
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="statusOptions"
              :reduce="(val) => val.value"
              :clearable="false"
              input-id="user-status"
            />
          </b-form-group>
        </b-col>


        <b-col cols="12" md="4">
          <b-form-group label="المسمى الوظيفي" label-for="user-role">
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="roleOptions"
              :reduce="(val) => val.value"
              :clearable="false"
              input-id="user-role"
            />
          </b-form-group>
        </b-col>


        <b-col cols="12" md="6" lg="4">
          <b-form-group label="تاريخ بدء العمل" label-for="birth-date">
            <flat-pickr class="form-control" v-model="date" placeholder="YYYY-MM-DD" />
          </b-form-group>
        </b-col>
      </b-card> -->

      <!-- Action Buttons -->
      <b-button variant="primary" class="mb-1 mb-sm-0 mr-0 mr-sm-1" @click="editUserInfo">
        <span> حفظ </span>
      </b-button>
      <b-button variant="outline-secondary" type="reset">
        <span> إعادة تعيين</span>
      </b-button>
    </validation-observer>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import flatPickr from "vue-flatpickr-component";
import {
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
  BInputGroup,
  BInputGroupAppend,
} from "bootstrap-vue";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { avatarText } from "@core/utils/filter";
import vSelect from "vue-select";
import { required, max ,email,minname} from "@validations";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ref } from "@vue/composition-api";
import useUsersList from "../users-list/useUsersList";
export default {
  setup(props) {
      const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const maxDate = new Date(today);
    maxDate.setMonth(maxDate.getMonth());
    const { resolveUserRoleVariant } = useUsersList();

    const genderOptions = [
      { label: "ذكر", value: 1 },
      { label: "أنثى", value: 2 },
    ];

    const statusOptions = [
      { label: "Pending", value: "pending" },
      { label: "Active", value: "active" },
      { label: "Inactive", value: "inactive" },
    ];

    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null);
    const previewEl = ref(null);

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, (base64) => {
      // eslint-disable-next-line no-param-reassign
      this.photo = base64;
    });

    return {
      date: "",
     
      photo: "",
      resolveUserRoleVariant,
      avatarText,
      genderOptions,
      statusOptions,
    max: maxDate,
      //  ? Demo - Update Image on click of update button
      refInputEl,
      previewEl,
      inputImageRenderer,
    };
  },

  mixins: [togglePasswordVisibility],

  components: {
    BInputGroupAppend,
    flatPickr,
    required,
    max,
    email,
    minname,
    BInputGroup,
    ValidationObserver,
    ValidationProvider,
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    togglePasswordVisibility,
    ToastificationContent,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    vSelect,
  },

  props: {
    // userData: {
    //   email: "",
    // },
  },

  data() {
    return {
      departmentOptions:[],
      roleOptions: [],
      positionOptions:[],
      isBenificary:false,
      optionService:[],
      optionServiceRole:[],
      userData: {
        first_name: "",
        last_name: "",
        username: "",
        email: "",
        password: "",
        confirm_password: "",
        address: "",
        birth_date: "",
        phone: "",
        role:{
          id:'',
          name:''
        },
        main_service_id:''
      },
    };
  },

  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },

  created() {
    this.getRoleOptions();
    this.getServiceType()
    this.getDepartmentOptions();
    this.getUsers()
  },

  methods: {
    updtaePer(){
  //    console.log(this.isBenificary)
      let data
      if(this.isBenificary==true){
         data={
          checked:1
        }
      }
      else{
         data={
          checked:0
        }
      }
      this.$http
              .post("/api/v1/information_manager/user-toggle-data-entry-role/"+this.$route.params.id,data)
              .then((res) => {
                //console.log(res);
                  this.$swal({
           title: "تم تعديل الصلاحيات بنجاح",
             
                icon: "success",
                confirmButtonText: "موافق",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            })
    },
    getDepartmentOptions(){
      this.departmentOptions=[]
      this.$http.get("/api/v1/departments").then((res) => {
//console.log(res.data)
let department = res.data.data;

department.forEach((el) => {
  this.departmentOptions.push({ label: el.name, value: el.id });

});
});

this.positionOptions=[]
this.$http.get("/api/v1/position").then((res) => {
//console.log(res.data)
let position = res.data.data.data;

position.forEach((el) => {
  this.positionOptions.push({ label: el.name, value: el.id });

});
});
    },
    getUsers(){
      this.$http.get("/api/v1/information_manager/users/"+this.$route.params.id).then((res) => {

 
        this.userData=res.data.data
        if(res.data.data.can_register==1){
          this.isBenificary=true
        }
      })
    },
      getServiceType() {
      this.$http.get("/api/v1/beneficiaries_page/get_dropdown_data").then((res) => {
        // //console.log(res);
        let proposal_services = res.data.data.main_services;
        proposal_services.forEach((el) => {
          this.optionService.push({ label: el.name, value: el.id });
        });
      });
    },
    editUserInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.simpleRules.validate().then((success) => {
          if (success) {
       
            this.$http
              .put("/api/v1/information_manager/users/"+this.$route.params.id, this.userData)
              .then((res) => {
                //console.log(res);
                  this.$swal({
           title: "تم تعديل المستخدم بنجاح",
             
                icon: "success",
                confirmButtonText: "موافق",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
               
              }).catch((error) => {
             this.$swal({
                    title: `${error.response.data.message}`,
             
                icon: "error",
                confirmButtonText: "موافق",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
                
              });
          }
        });
      });
    },
    getRoleOptions() {
      this.$http.get("/api/v1/information_manager/role_management/roles").then((res) => {
        //console.log(res);
        let Roles = res.data.roles;
       
        Roles.forEach((el) => {
          this.roleOptions.push({ label: el.name, value: el.id });

        });
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>